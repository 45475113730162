import { fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import qs from 'qs'

import { apiClientId, apiClientSecret, appUrl } from '@/config/env'

export const apiBaseQuery = fetchBaseQuery({
  baseUrl: `${appUrl}/api`,
  // baseUrl: `${apiBaseUrl}/${apiVersion}`,
  paramsSerializer: (params) => qs.stringify(params),
  prepareHeaders: (headers) => {
    headers.set('Authorization', `Basic ${btoa(`${apiClientId}:${apiClientSecret}`)}`)
    headers.set('Accept', 'application/json')
    return headers
  },
})
