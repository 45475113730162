import { AppProps } from 'next/app'
import Head from 'next/head'
import { useRouter } from 'next/router'
import { appWithTranslation } from 'next-i18next'
import * as React from 'react'
import { useEffect, useState } from 'react'
import ReactGA from 'react-ga4'
import { Provider } from 'react-redux'

import '@/styles/swiper.css'
import '@/styles/theme.css'
import '@/styles/fonts.css'
import '@/styles/google-map.css'

import Loading from '@/components/Loading'
import { googleAnalyticId } from '@/config/env'
import { wrapper } from '@/store'

if (googleAnalyticId.length) {
  ReactGA.initialize(googleAnalyticId)
}
const MyApp: React.FC<AppProps> = ({ Component, ...rest }) => {
  const { store, props } = wrapper.useWrappedStore(rest)

  const router = useRouter()

  const [progress, setProgress] = useState({
    isRouteChanging: false,
    loadingKey: 0,
  })

  useEffect(() => {
    const handleRouteChangeStart = () => {
      setProgress((prevState) => ({
        ...prevState,
        isRouteChanging: true,
        loadingKey: prevState.loadingKey ^ 1,
      }))
    }

    const handleRouteChangeEnd = () => {
      setProgress((prevState) => ({
        ...prevState,
        isRouteChanging: false,
      }))
    }

    router.events.on('routeChangeStart', handleRouteChangeStart)
    router.events.on('routeChangeComplete', handleRouteChangeEnd)
    router.events.on('routeChangeError', handleRouteChangeEnd)

    return () => {
      router.events.off('routeChangeStart', handleRouteChangeStart)
      router.events.off('routeChangeComplete', handleRouteChangeEnd)
      router.events.off('routeChangeError', handleRouteChangeEnd)
    }
  }, [router.events])

  return (
    <>
      <Head>
        <meta
          name='viewport'
          content='initial-scale=1, width=device-width'
        />
      </Head>

      <Loading
        key={progress.loadingKey}
        isRouteChanging={progress.isRouteChanging}
      />

      <Provider store={store}>
        <Component {...props.pageProps} />
      </Provider>
    </>
  )
}

export default appWithTranslation(MyApp)
