import { createApi } from '@reduxjs/toolkit/query/react'
import { HYDRATE } from 'next-redux-wrapper'

import {
  AboutAboutSezResponse,
  AboutAdminMembersResponse,
  AboutAdminStructureResponse,
  AboutChairmanResponse,
  AboutDutiesResponse,
  AboutHeaderResponse,
  AboutJourneyResponse,
  AboutMembersResponse,
  AboutMinisterSpeechResponse,
  AboutNationalCouncilMinistersResponse,
  AboutNationalCouncilStructuresResponse,
  AboutSezInstitutionalResponse,
  AboutStructuresResponse,
  BaseAboutHeaderRequest,
  BaseAboutListDynamicRequest,
} from '@/types/about'
import { BasicHeaders, ListBasicRequest } from '@/types/common'
import { apiBaseQuery } from '@/utils/api'

const api = createApi({
  reducerPath: 'about',
  baseQuery: apiBaseQuery,
  tagTypes: ['About'],
  refetchOnMountOrArgChange: true,
  keepUnusedDataFor: 259200, // 3 days
  endpoints: (builder) => ({
    getAboutHeader: builder.query<AboutHeaderResponse, BaseAboutHeaderRequest>({
      query: ({ params: { page }, headers }) => ({
        headers,
        url: `/v2/${page}/header`,
        providesTags: ['About'],
      }),
    }),
    getAboutDuties: builder.query<AboutDutiesResponse, BaseAboutHeaderRequest>({
      query: ({ params: { page }, headers }) => ({
        headers,
        url: `/v2/${page}/duties`,
        providesTags: ['About'],
      }),
    }),
    getAboutSez: builder.query<AboutAboutSezResponse, BasicHeaders>({
      query: ({ headers }) => ({
        headers,
        url: '/v2/about/sez-about',
        providesTags: ['About'],
      }),
    }),
    getAboutMinisterSpeech: builder.query<AboutMinisterSpeechResponse, BasicHeaders>({
      query: ({ headers }) => ({
        headers,
        url: '/v2/about/minister-speech',
        providesTags: ['About'],
      }),
    }),
    getAboutJourney: builder.query<AboutJourneyResponse, ListBasicRequest>({
      query: ({ headers, params }) => ({
        headers,
        params,
        url: '/v1/about/journeys',
        providesTags: ['About'],
      }),
    }),
    getAboutSezInstitutional: builder.query<AboutSezInstitutionalResponse, BasicHeaders>({
      query: ({ headers }) => ({
        headers,
        url: '/v1/about/sez-institutional',
        providesTags: ['About'],
      }),
    }),
    getAboutMembers: builder.query<AboutAdminMembersResponse, BaseAboutListDynamicRequest>({
      query: ({ headers, params: { page } }) => ({
        headers,
        url: `/v2/${page}/members`,
        providesTags: ['About'],
      }),
    }),
    getAboutGeneralSecretariatMembers: builder.query<AboutMembersResponse, BasicHeaders>({
      query: ({ headers }) => ({
        headers,
        url: '/v2/general-secretariat/members',
        providesTags: ['About'],
      }),
    }),
    getAboutAdminStructure: builder.query<AboutAdminStructureResponse, BasicHeaders>({
      query: ({ headers }) => ({
        headers,
        url: '/v2/about-administrator/structure',
        providesTags: ['About'],
      }),
    }),
    getAboutStructure: builder.query<AboutStructuresResponse, BaseAboutHeaderRequest>({
      query: ({ params: { page }, headers }) => ({
        headers,
        url: `/v2/${page}/structures`,
        providesTags: ['about'],
      }),
    }),
    getAboutChairman: builder.query<AboutChairmanResponse, BaseAboutHeaderRequest>({
      query: ({ params: { page }, headers }) => ({
        headers,
        url: `/v2/${page}/chairman`,
        providesTags: ['about'],
      }),
    }),
    getAboutNationalCouncilStructures: builder.query<AboutNationalCouncilStructuresResponse, BasicHeaders>({
      query: ({ headers }) => ({
        headers,
        url: '/v2/national-council/structures',
        providesTags: ['About'],
      }),
    }),
    getAboutNationalCouncilMinistersStructures: builder.query<AboutNationalCouncilMinistersResponse, BasicHeaders>({
      query: ({ headers }) => ({
        headers,
        url: '/v2/national-council/ministers',
        providesTags: ['About'],
      }),
    }),
  }),
  extractRehydrationInfo(action, { reducerPath }) {
    if (action.type === HYDRATE) {
      return action.payload[reducerPath]
    }
  },
})

// Export hooks for usage in functional components
export const { util: aboutUtil } = api

// Export endpoints for use in SSR
export const {
  getAboutHeader,
  getAboutDuties,
  getAboutSez,
  getAboutMinisterSpeech,
  getAboutJourney,
  getAboutSezInstitutional,
  getAboutMembers,
  getAboutGeneralSecretariatMembers,
  getAboutAdminStructure,
  getAboutStructure,
  getAboutChairman,
  getAboutNationalCouncilStructures,
  getAboutNationalCouncilMinistersStructures,
} = api.endpoints

export default api
