import about from '@/services/about'
import apps from '@/services/apps'
import booklet from '@/services/booklet'
import buildNewSez from '@/services/build-new-sez'
import businessCategory from '@/services/business-category'
import businessSector from '@/services/business-sector'
import gallery from '@/services/gallery'
import home from '@/services/home'
import incentive from '@/services/incentive'
import jdih from '@/services/jdih'
import jobs from '@/services/jobs'
import news from '@/services/news'
import ppid from '@/services/ppid'
import ppidFilingObjections from '@/services/ppid-filing-objections'
import ppidInformationRequest from '@/services/ppid-information-request'
import ppidSurvey from '@/services/ppid-survey'
import report from '@/services/report'
import sezRegions from '@/services/sez-regions'
import tags from '@/services/tags'
import whySez from '@/services/why-sez'

const rootServices = {
  reducers: {
    // sezRegions
    [sezRegions.reducerPath]: sezRegions.reducer,
    [businessCategory.reducerPath]: businessCategory.reducer,
    [businessSector.reducerPath]: businessSector.reducer,
    [home.reducerPath]: home.reducer,
    [incentive.reducerPath]: incentive.reducer,
    [news.reducerPath]: news.reducer,
    [tags.reducerPath]: tags.reducer,
    [about.reducerPath]: about.reducer,
    [ppid.reducerPath]: ppid.reducer,
    [jdih.reducerPath]: jdih.reducer,
    [jobs.reducerPath]: jobs.reducer,
    [report.reducerPath]: report.reducer,
    [gallery.reducerPath]: gallery.reducer,
    [booklet.reducerPath]: booklet.reducer,
    [ppidSurvey.reducerPath]: ppidSurvey.reducer,
    [ppidInformationRequest.reducerPath]: ppidInformationRequest.reducer,
    [ppidFilingObjections.reducerPath]: ppidFilingObjections.reducer,
    [gallery.reducerPath]: gallery.reducer,
    [buildNewSez.reducerPath]: buildNewSez.reducer,
    [apps.reducerPath]: apps.reducer,
    [whySez.reducerPath]: whySez.reducer,
  },
  middlewares: [
    // sezRegions
    sezRegions.middleware,
    businessCategory.middleware,
    businessSector.middleware,
    home.middleware,
    incentive.middleware,
    news.middleware,
    tags.middleware,
    about.middleware,
    ppid.middleware,
    jdih.middleware,
    jobs.middleware,
    report.middleware,
    gallery.middleware,
    booklet.middleware,
    ppidSurvey.middleware,
    ppidInformationRequest.middleware,
    ppidFilingObjections.middleware,
    gallery.middleware,
    buildNewSez.middleware,
    apps.middleware,
    whySez.middleware,
  ],
}

export default rootServices
