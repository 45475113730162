import { createApi } from '@reduxjs/toolkit/query/react'
import { HYDRATE } from 'next-redux-wrapper'

import { BasicHeaders } from '@/types/common'
import {
  BaseIncentiveListDynamicRequest,
  IncentiveComparisonDataListResponse,
  IncentiveComparisonSectionResponse,
  IncentiveComparisonTypeListResponse,
  IncentiveFiscalListResponse,
  IncentiveHeaderResponse,
  IncentiveImportGoodsResponse,
  IncentiveTaxIncomePolicyResponse,
} from '@/types/incentive'
import { apiBaseQuery } from '@/utils/api'

const api = createApi({
  reducerPath: 'incentive',
  baseQuery: apiBaseQuery,
  tagTypes: ['Incentive'],
  refetchOnMountOrArgChange: true,
  keepUnusedDataFor: 259200, // 3 days
  endpoints: (builder) => ({
    getIncentiveHeader: builder.query<IncentiveHeaderResponse, BasicHeaders>({
      query: ({ headers }) => ({
        headers,
        url: '/v2/incentive/header',
        providesTags: ['Incentive'],
      }),
    }),
    getIncentiveFiscalList: builder.query<IncentiveFiscalListResponse, BaseIncentiveListDynamicRequest>({
      query: (listRequest) => ({
        ...listRequest,
        url: '/v2/incentive/fiscal-facilities',
        providesTags: ['Incentive'],
      }),
    }),
    getIncentiveNonFiscalList: builder.query<IncentiveFiscalListResponse, BaseIncentiveListDynamicRequest>({
      query: (listRequest) => ({
        ...listRequest,
        url: '/v2/incentive/non-fiscal-facilities',
        providesTags: ['Incentive'],
      }),
    }),
    getIncentiveTaxIncomePolicy: builder.query<IncentiveTaxIncomePolicyResponse, BasicHeaders>({
      query: ({ headers }) => ({
        headers,
        url: '/v2/incentive/tax-income-policy',
        providesTags: ['Incentive'],
      }),
    }),
    getIncentiveImportGoods: builder.query<IncentiveImportGoodsResponse, BasicHeaders>({
      query: ({ headers }) => ({
        headers,
        url: '/v2/incentive/import-goods',
        providesTags: ['Incentive'],
      }),
    }),
    getIncentiveComparisonSection: builder.query<IncentiveComparisonSectionResponse, BasicHeaders>({
      query: ({ headers }) => ({
        headers,
        url: '/v1/incentive/comparison',
        providesTags: ['Incentive'],
      }),
    }),
    getIncentiveComparisonTypeList: builder.query<IncentiveComparisonTypeListResponse, BaseIncentiveListDynamicRequest>(
      {
        query: (listRequest) => ({
          ...listRequest,
          url: '/v1/incentive/comparison-type',
          providesTags: ['Incentive'],
        }),
      }
    ),
    getIncentiveComparisonDataList: builder.query<IncentiveComparisonDataListResponse, BaseIncentiveListDynamicRequest>(
      {
        query: (listRequest) => ({
          ...listRequest,
          url: '/v1/incentive/comparison-data',
          providesTags: ['Incentive'],
        }),
      }
    ),
  }),
  extractRehydrationInfo(action, { reducerPath }) {
    if (action.type === HYDRATE) {
      return action.payload[reducerPath]
    }
  },
})

// Export hooks for usage in functional components
export const {
  useLazyGetIncentiveHeaderQuery,
  useLazyGetIncentiveFiscalListQuery,
  useLazyGetIncentiveNonFiscalListQuery,
  useLazyGetIncentiveTaxIncomePolicyQuery,
  useLazyGetIncentiveImportGoodsQuery,
  useLazyGetIncentiveComparisonTypeListQuery,
  useLazyGetIncentiveComparisonDataListQuery,
  util: businessCategoriesUtil,
} = api

export const {
  getIncentiveHeader,
  getIncentiveFiscalList,
  getIncentiveNonFiscalList,
  getIncentiveTaxIncomePolicy,
  getIncentiveImportGoods,
  getIncentiveComparisonSection,
  getIncentiveComparisonTypeList,
  getIncentiveComparisonDataList,
} = api.endpoints

export default api
