import { createApi } from '@reduxjs/toolkit/query/react'
import { HYDRATE } from 'next-redux-wrapper'

import { BaseResponse, ListBasicRequest } from '@/types/common'
import { ContentListResponse, RequestInformationRequestForm } from '@/types/ppid-information-request'
import { apiBaseQuery } from '@/utils/api'

const api = createApi({
  reducerPath: 'ppid-information',
  baseQuery: apiBaseQuery,
  tagTypes: ['PPID Information'],
  refetchOnMountOrArgChange: true,
  keepUnusedDataFor: 259200, // 3 days
  endpoints: (builder) => ({
    getPpidInformationHowToObtain: builder.query<ContentListResponse, ListBasicRequest>({
      query: (request) => ({
        ...request,
        url: '/v1/ppid/information-how-to-obtains',
        providesTags: ['PPID Information'],
      }),
    }),
    getPpidInformationHowToGets: builder.query<ContentListResponse, ListBasicRequest>({
      query: (request) => ({
        ...request,
        url: '/v1/ppid/information-how-to-gets',
        providesTags: ['PPID Information'],
      }),
    }),
    postRequestInformation: builder.mutation<BaseResponse<any>, RequestInformationRequestForm>({
      query: ({ body }) => ({
        method: 'POST',
        url: '/v1/ppid/information-request-form/store',
        body,
      }),
      invalidatesTags: ['PPID Information'],
    }),
  }),
  extractRehydrationInfo(action, { reducerPath }) {
    if (action.type === HYDRATE) {
      return action.payload[reducerPath]
    }
  },
})

// Export hooks for usage in functional components
export const { usePostRequestInformationMutation, util: tagsUtil } = api

// Export endpoints for use in SSR
export const { getPpidInformationHowToObtain, getPpidInformationHowToGets } = api.endpoints

export default api
