import { createApi } from '@reduxjs/toolkit/query/react'
import { HYDRATE } from 'next-redux-wrapper'

import {
  BaseBookletDocumentRequest,
  BaseBookletHeaderRequest,
  BaseBookletListDynamicRequest,
  BookletDocumentDetailResponse,
  BookletDocumentsResponse,
  BookletHeaderResponse,
  BookletVideosResponse,
} from '@/types/booklet'
import { BaseResponse, DownloadBasicRequest } from '@/types/common'
import { apiBaseQuery } from '@/utils/api'

const api = createApi({
  reducerPath: 'booklet',
  baseQuery: apiBaseQuery,
  tagTypes: ['Booklet'],
  refetchOnMountOrArgChange: true,
  keepUnusedDataFor: 259200, // 3 days
  endpoints: (builder) => ({
    getBookletHeader: builder.query<BookletHeaderResponse, BaseBookletHeaderRequest>({
      query: ({ params: { page }, headers }) => ({
        headers,
        url: `/v2/${page}/header`,
        providesTags: ['Booklet'],
      }),
    }),
    getBookletDocuments: builder.query<BookletDocumentsResponse, BaseBookletListDynamicRequest>({
      query: ({ headers, params: { page } }) => ({
        headers,
        url: `/v2/${page}/documents`,
        providesTags: ['Booklet'],
      }),
    }),
    getDetailBooklet: builder.query<BookletDocumentDetailResponse, BaseBookletDocumentRequest>({
      query: ({ headers, params: { page, slug } }) => ({
        headers,
        url: `/v2/${page}/document/${slug}`,
        providesTags: ['Booklet'],
      }),
    }),
    getBannerVideos: builder.query<BookletVideosResponse, BaseBookletListDynamicRequest>({
      query: ({ headers, params }) => ({
        headers,
        params,
        url: `/v1/${params.page}/videos`,
        providesTags: ['Booklet'],
      }),
    }),
    getBookletVideos: builder.query<BookletVideosResponse, BaseBookletListDynamicRequest>({
      query: ({ headers, params }) => ({
        headers,
        params,
        url: `/v1/${params.page}/videos`,
        providesTags: ['Booklet'],
      }),
    }),
    postBookletDownload: builder.mutation<BaseResponse<any>, DownloadBasicRequest>({
      query: ({ xid, body }) => ({
        method: 'POST',
        url: `/v1/booklet/document/${xid}/download-form/store`,
        body,
      }),
      invalidatesTags: ['Booklet'],
    }),
  }),
  extractRehydrationInfo(action, { reducerPath }) {
    if (action.type === HYDRATE) {
      return action.payload[reducerPath]
    }
  },
})

// Export hooks for usage in functional components
export const { usePostBookletDownloadMutation, util: aboutUtil } = api

// Export endpoints for use in SSR
export const { getBookletHeader, getBookletDocuments, getDetailBooklet, getBannerVideos, getBookletVideos } =
  api.endpoints

export default api
