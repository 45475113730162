import { createApi } from '@reduxjs/toolkit/query/react'
import { HYDRATE } from 'next-redux-wrapper'

import { BasicHeaders } from '@/types/common'
import {
  NewsDetailRequest,
  NewsDetailResponse,
  NewsHeaderResponse,
  NewsListRequest,
  NewsListResponse,
} from '@/types/news'
import { apiBaseQuery } from '@/utils/api'

const api = createApi({
  reducerPath: 'news',
  baseQuery: apiBaseQuery,
  tagTypes: ['News'],
  refetchOnMountOrArgChange: true,
  keepUnusedDataFor: 259200, // 3 days
  endpoints: (builder) => ({
    getNewsHeader: builder.query<NewsHeaderResponse, BasicHeaders>({
      query: ({ headers }) => ({
        headers,
        url: '/v2/news-header',
        providesTags: ['News'],
      }),
    }),
    getNews: builder.query<NewsListResponse, NewsListRequest>({
      query: (sezRegionListRequest) => ({
        ...sezRegionListRequest,
        url: '/v2/news',
        providesTags: ['News'],
      }),
    }),
    getDetailNews: builder.query<NewsDetailResponse, NewsDetailRequest>({
      query: ({ params: { slug }, headers }) => ({
        headers,
        url: `/v2/news/${slug}`,
        providesTags: ['News'],
      }),
    }),
  }),
  extractRehydrationInfo(action, { reducerPath }) {
    if (action.type === HYDRATE) {
      return action.payload[reducerPath]
    }
  },
})

// Export hooks for usage in functional components
export const { useLazyGetNewsQuery, util: newsUtil } = api

// Export endpoints for use in SSR
export const { getNewsHeader, getNews, getDetailNews } = api.endpoints

export default api
