import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const initialState: { isLoading: boolean } = {
  isLoading: false,
}

export const app = createSlice({
  name: 'app-reducer',
  initialState,
  reducers: {
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload
    },
  },
})

export const { setIsLoading: appSetIsLoading } = app.actions

export default app.reducer
