import { createApi } from '@reduxjs/toolkit/query/react'
import { HYDRATE } from 'next-redux-wrapper'

import { BasicHeaders, ListBasicRequest } from '@/types/common'
import {
  ContentListResponse,
  HeroResponse,
  ProcedureTitleResponse,
  StructureResponse,
  SubmissionTitleResponse,
} from '@/types/ppid'
import { apiBaseQuery } from '@/utils/api'

const api = createApi({
  reducerPath: 'ppid',
  baseQuery: apiBaseQuery,
  tagTypes: ['PPID'],
  refetchOnMountOrArgChange: true,
  keepUnusedDataFor: 259200, // 3 days
  endpoints: (builder) => ({
    getPpidHero: builder.query<HeroResponse, BasicHeaders>({
      query: (request) => ({
        ...request,
        url: '/v2/ppid/hero',
        providesTags: ['PPID'],
      }),
    }),
    getPpidProcedureTitle: builder.query<ProcedureTitleResponse, BasicHeaders>({
      query: (request) => ({
        ...request,
        url: '/v1/ppid/procedure/title',
        providesTags: ['PPID'],
      }),
    }),
    getPpidProcedureList: builder.query<ContentListResponse, ListBasicRequest>({
      query: (request) => ({
        ...request,
        url: '/v2/ppid/procedure',
        providesTags: ['PPID'],
      }),
    }),
    getPpidStructure: builder.query<StructureResponse, BasicHeaders>({
      query: (request) => ({
        ...request,
        url: '/v2/ppid/structure',
        providesTags: ['PPID'],
      }),
    }),
    getPpidSubmissionTitle: builder.query<SubmissionTitleResponse, BasicHeaders>({
      query: (request) => ({
        ...request,
        url: '/v1/ppid/submission/title',
        providesTags: ['PPID'],
      }),
    }),
    getPpidSubmissionList: builder.query<ContentListResponse, ListBasicRequest>({
      query: (request) => ({
        ...request,
        url: '/v2/ppid/submission',
        providesTags: ['PPID'],
      }),
    }),
  }),
  extractRehydrationInfo(action, { reducerPath }) {
    if (action.type === HYDRATE) {
      return action.payload[reducerPath]
    }
  },
})

// Export hooks for usage in functional components
export const { util: tagsUtil } = api

// Export endpoints for use in SSR
export const {
  getPpidHero,
  getPpidProcedureTitle,
  getPpidStructure,
  getPpidProcedureList,
  getPpidSubmissionTitle,
  getPpidSubmissionList,
} = api.endpoints

export default api
