export const environment = process.env.NODE_ENV
export const isProduction = environment === 'production'

// app
export const appUrl = process.env.APP_URL || 'http://localhost:3000'
export const appName = process.env.APP_NAME || 'App Name'
export const appVersion = process.env.APP_VERSION || 'v0.0.0'
export const appBuildSignature = process.env.APP_BUILD_SIGNATURE || 'local'

// api
export const apiBaseUrl = String(process.env.API_BASE_URL)
export const apiClientId = String(process.env.API_CLIENT_ID)
export const apiClientSecret = String(process.env.API_CLIENT_SECRET)
export const apiPlatformId = Number(process.env.API_PLATFORM_ID || 0)
export const apiVersion = String(process.env.API_VERSION || 'v1')

// mock api
export const mockApiBaseUrl = String(process.env.MOCK_API_BASE_URL)
export const mockApiClientId = String(process.env.MOCK_API_CLIENT_ID)
export const mockApiClientSecret = String(process.env.MOCK_API_CLIENT_SECRET)

// Google api
export const gMapsApiKey = String(process.env.GMAPS_API_KEY || '')
export const googleAnalyticId = String(process.env.GOOGLE_ANALYTIC_ID || '')
