import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { SezRegion } from '@/types/sez-regions'

const initialState: {
  isHover: boolean
  isOpenInfoWindowById: string
  isListOpen: boolean
  isZoom: boolean
  isDetail: boolean
  isNextDetail: boolean
  isFilter: boolean
  isFilterOpen: boolean
  slug: string
  filterKeyword: string
  filterCategory: string
  filterIndustryType: string
  draggable: boolean
  regionDetail: SezRegion | null
} = {
  isHover: false,
  isOpenInfoWindowById: '',
  isListOpen: false,
  isZoom: false,
  isDetail: false,
  isNextDetail: false,
  isFilter: false,
  isFilterOpen: false,
  slug: '',
  filterKeyword: '',
  filterCategory: '',
  filterIndustryType: '',
  draggable: false,
  regionDetail: null,
}

export const googleMapReducer = createSlice({
  name: 'google-map-reducer',
  initialState,
  reducers: {
    setIsHover: (state, action: PayloadAction<boolean>) => {
      state.isHover = action.payload
    },
    setIsOpenInfoWindow: (state, action: PayloadAction<string>) => {
      state.isOpenInfoWindowById = action.payload
    },
    setIsListOpen: (state, action: PayloadAction<boolean>) => {
      state.isListOpen = action.payload
    },
    setIsZoom: (state, action: PayloadAction<boolean>) => {
      state.isZoom = action.payload
    },
    setIsDetail: (state, action: PayloadAction<boolean>) => {
      state.isDetail = action.payload
    },
    setIsNextDetail: (state, action: PayloadAction<boolean>) => {
      state.isNextDetail = action.payload
    },
    setIsFilter: (state, action: PayloadAction<boolean>) => {
      state.isFilter = action.payload
    },
    setIsFilterOpen: (state, action: PayloadAction<boolean>) => {
      state.isFilterOpen = action.payload
    },
    setSlug: (state, action: PayloadAction<string>) => {
      state.slug = action.payload
    },
    setFilterKeyword: (state, action: PayloadAction<string>) => {
      state.filterKeyword = action.payload
    },
    setFilterCategory: (state, action: PayloadAction<string>) => {
      state.filterCategory = action.payload
    },
    setFilterIndustryType: (state, action: PayloadAction<string>) => {
      state.filterIndustryType = action.payload
    },
    setDraggable: (state, action: PayloadAction<boolean>) => {
      state.draggable = action.payload
    },
    setRegionDetail: (state, action: PayloadAction<SezRegion | null>) => {
      state.regionDetail = action.payload
    },
    clearData: (state) => {
      state.isHover = false
      state.isListOpen = false
      state.isZoom = false
      state.isDetail = false
      state.isFilter = false
      state.isFilterOpen = false
      state.draggable = false
      state.slug = ''
      state.filterKeyword = ''
      state.filterCategory = ''
      state.filterIndustryType = ''
      state.regionDetail = null
    },
  },
})

export const {
  setFilterKeyword: googleMapSetFilterKeyword,
  setFilterCategory: googleMapSetFilterCategory,
  setIsOpenInfoWindow: googleMapSetIsOpenInfoWindow,
  setFilterIndustryType: googleMapSetFilterIndustryType,
  setSlug: googleMapSetSlug,
  setIsHover: googleMapSetIsHover,
  setIsListOpen: googleMapSetIsListOpen,
  setIsZoom: googleMapSetIsZoom,
  setIsFilter: googleMapSetIsFilter,
  setIsFilterOpen: googleMapSetIsFilterOpen,
  setIsDetail: googleMapSetIsDetail,
  setIsNextDetail: googleMapSetIsNextDetail,
  setRegionDetail: googleMapSetRegionDetail,
  clearData: googleMapClearData,
  setDraggable: googleMapSetDraggable,
} = googleMapReducer.actions

export default googleMapReducer.reducer
