import { createApi } from '@reduxjs/toolkit/query/react'
import { HYDRATE } from 'next-redux-wrapper'

import { BasicHeaders } from '@/types/common'
import {
  GalleryDetailRequest,
  GalleryDetailResponse,
  GalleryHeaderResponse,
  GalleryListRequest,
  GalleryListResponse,
} from '@/types/gallery'
import { apiBaseQuery } from '@/utils/api'

const api = createApi({
  reducerPath: 'gallery',
  baseQuery: apiBaseQuery,
  tagTypes: ['Gallery'],
  refetchOnMountOrArgChange: true,
  keepUnusedDataFor: 259200, // 3 days
  endpoints: (builder) => ({
    getGalleryHeader: builder.query<GalleryHeaderResponse, BasicHeaders>({
      query: ({ headers }) => ({
        headers,
        url: '/v2/gallery-album-header',
        providesTags: ['Gallery'],
      }),
    }),
    getGalleryAlbumList: builder.query<GalleryListResponse, GalleryListRequest>({
      query: (listRequest) => ({
        ...listRequest,
        url: '/v2/gallery-albums',
        providesTags: ['Gallery'],
      }),
    }),
    getGalleryDetail: builder.query<GalleryDetailResponse, GalleryDetailRequest>({
      query: ({ params: { slug }, headers }) => ({
        headers,
        url: `/v2/gallery-album/${slug}`,
        providesTags: ['Gallery'],
      }),
    }),
  }),
  extractRehydrationInfo(action, { reducerPath }) {
    if (action.type === HYDRATE) {
      return action.payload[reducerPath]
    }
  },
})

// Export hooks for usage in functional components
export const { useLazyGetGalleryAlbumListQuery, util: newsUtil } = api

// Export endpoints for use in SSR
export const { getGalleryHeader, getGalleryAlbumList, getGalleryDetail } = api.endpoints

export default api
