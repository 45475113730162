import { createApi } from '@reduxjs/toolkit/query/react'
import { HYDRATE } from 'next-redux-wrapper'

import { BaseResponse, ListBasicRequest } from '@/types/common'
import { ContentListResponse, FilingObjectionsRequestForm } from '@/types/ppid-filing-objections'
import { apiBaseQuery } from '@/utils/api'

const api = createApi({
  reducerPath: 'ppid-object',
  baseQuery: apiBaseQuery,
  tagTypes: ['PPID Object'],
  refetchOnMountOrArgChange: true,
  keepUnusedDataFor: 259200, // 3 days
  endpoints: (builder) => ({
    getPpidFilingObjectionsReason: builder.query<ContentListResponse, ListBasicRequest>({
      query: (request) => ({
        ...request,
        url: '/v1/ppid/objection-reason',
        providesTags: ['PPID Object'],
      }),
    }),
    ppidSentFilingObjectionsReason: builder.mutation<BaseResponse<any>, FilingObjectionsRequestForm>({
      query: ({ body }) => ({
        method: 'POST',
        url: '/v1/ppid/objection-form/store',
        body,
      }),
      invalidatesTags: ['PPID Object'],
    }),
  }),
  extractRehydrationInfo(action, { reducerPath }) {
    if (action.type === HYDRATE) {
      return action.payload[reducerPath]
    }
  },
})

// Export hooks for usage in functional components
export const { usePpidSentFilingObjectionsReasonMutation, util: tagsUtil } = api

// Export endpoints for use in SSR
export const { getPpidFilingObjectionsReason } = api.endpoints

export default api
