import { createApi } from '@reduxjs/toolkit/query/react'
import { HYDRATE } from 'next-redux-wrapper'

import { BasicHeaders } from '@/types/common'
import {
  BaseRequest,
  HowToInvestResponse,
  ListBasicRequest,
  ProposeNewResponse,
  SezRegionContactListResponse,
  SezRegionDetailImagesResponse,
  SezRegionDetailInfrastructureListResponse,
  SezRegionDetailInvestmentListResponse,
  SezRegionDetailProfileResponse,
  SezRegionDetailRegulationListResponse,
  SezRegionDetailResponse,
  SezRegionDetailVideoListResponse,
  SezRegionListRequest,
  SezRegionListResponse,
} from '@/types/sez-regions'
import { apiBaseQuery } from '@/utils/api'
import { objectOmit } from '@/utils/formatter'

const api = createApi({
  reducerPath: 'sez-regions',
  baseQuery: apiBaseQuery,
  tagTypes: ['Sez Regions'],
  refetchOnMountOrArgChange: true,
  keepUnusedDataFor: 259200, // 3 days
  endpoints: (builder) => ({
    getListSezRegions: builder.query<SezRegionListResponse, SezRegionListRequest>({
      query: (sezRegionListRequest) => ({
        ...sezRegionListRequest,
        url: '/v1/sez-regions',
        providesTags: ['Sez Regions'],
      }),
    }),
    getDetailSezRegions: builder.query<SezRegionDetailResponse, BaseRequest>({
      query: ({ params, headers }) => ({
        headers,
        url: `/v2/sez-regions/${params.slug}/show`,
        providesTags: ['Sez Regions'],
      }),
    }),
    getDetailImages: builder.query<SezRegionDetailImagesResponse, ListBasicRequest>({
      query: ({ params, headers }) => ({
        headers,
        params: objectOmit(params, ['slug']),
        url: `/v2/sez-regions/${params.slug}/images`,
        providesTags: ['Sez Regions'],
      }),
    }),
    getDetailProfile: builder.query<SezRegionDetailProfileResponse, BaseRequest>({
      query: ({ params, headers }) => ({
        headers,
        params: objectOmit(params, ['slug']),
        url: `/v2/sez-regions/${params.slug}/profile`,
        providesTags: ['Sez Regions'],
      }),
    }),
    getDetailRegulationList: builder.query<SezRegionDetailRegulationListResponse, ListBasicRequest>({
      query: ({ params, headers }) => ({
        headers,
        params: objectOmit(params, ['slug']),
        url: `/v1/sez-regions/${params.slug}/regulations`,
        providesTags: ['Sez Regions'],
      }),
    }),
    getDetailInfrastructureList: builder.query<SezRegionDetailInfrastructureListResponse, ListBasicRequest>({
      query: ({ params, headers }) => ({
        headers,
        params: objectOmit(params, ['slug']),
        url: `/v1/sez-regions/${params.slug}/infrastructures`,
        providesTags: ['Sez Regions'],
      }),
    }),
    getDetailInvestmentsList: builder.query<SezRegionDetailInvestmentListResponse, ListBasicRequest>({
      query: ({ params, headers }) => ({
        headers,
        params: objectOmit(params, ['slug']),
        url: `/v1/sez-regions/${params.slug}/investments`,
        providesTags: ['Sez Regions'],
      }),
    }),
    getDetailVideoList: builder.query<SezRegionDetailVideoListResponse, ListBasicRequest>({
      query: ({ params, headers }) => ({
        headers,
        params: objectOmit(params, ['slug']),
        url: `/v1/sez-regions/${params.slug}/videos`,
        providesTags: ['Sez Regions'],
      }),
    }),
    getDetailContactList: builder.query<SezRegionContactListResponse, ListBasicRequest>({
      query: ({ params, headers }) => ({
        headers,
        params: objectOmit(params, ['slug']),
        url: `/v1/sez-regions/${params.slug}/contacts`,
        providesTags: ['Sez Regions'],
      }),
    }),
    getHowToInvestSez: builder.query<HowToInvestResponse, BasicHeaders>({
      query: ({ headers }) => ({
        headers,
        url: '/v1/how-to-invest-sez',
        providesTags: ['Sez Regions'],
      }),
    }),
    getProposeNewSez: builder.query<ProposeNewResponse, BasicHeaders>({
      query: ({ headers }) => ({
        headers,
        url: '/v2/propose-new-sez',
        providesTags: ['Sez Regions'],
      }),
    }),
  }),
  extractRehydrationInfo(action, { reducerPath }) {
    if (action.type === HYDRATE) {
      return action.payload[reducerPath]
    }
  },
})

// Export hooks for usage in functional components
export const {
  useLazyGetListSezRegionsQuery,
  useLazyGetDetailSezRegionsQuery,
  useLazyGetDetailProfileQuery,
  useLazyGetDetailImagesQuery,
  useLazyGetDetailContactListQuery,
  useLazyGetDetailInfrastructureListQuery,
  useLazyGetDetailRegulationListQuery,
  useLazyGetDetailVideoListQuery,
  util: sezRegionsUtil,
} = api

// Export endpoints for use in SSR
export const {
  getDetailSezRegions,
  getListSezRegions,
  getDetailImages,
  getDetailProfile,
  getDetailRegulationList,
  getDetailInfrastructureList,
  getDetailInvestmentsList,
  getDetailVideoList,
  getDetailContactList,
  getHowToInvestSez,
  getProposeNewSez,
} = api.endpoints

export default api
