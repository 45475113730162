import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const initialState: {
  isEventFilter: boolean
  isEventFilterFirstTime: boolean
  selected: number
  skip: number
  limit: number
  keyword: string
  year: string
  typeXid: string
} = {
  isEventFilter: false,
  isEventFilterFirstTime: false,
  selected: 0,
  skip: 0,
  limit: 10,
  keyword: '',
  year: '',
  typeXid: '',
}

export const jdih = createSlice({
  name: 'jdih-reducer',
  initialState,
  reducers: {
    setIsEventFilter: (state, action: PayloadAction<boolean>) => {
      state.isEventFilter = action.payload
    },
    setSelected: (state, action: PayloadAction<number>) => {
      state.selected = action.payload
    },
    setSkip: (state, action: PayloadAction<number>) => {
      state.skip = action.payload
    },
    setLimit: (state, action: PayloadAction<number>) => {
      state.limit = action.payload
    },
    setKeyword: (state, action: PayloadAction<string>) => {
      state.keyword = action.payload
    },
    setYear: (state, action: PayloadAction<string>) => {
      state.year = action.payload
    },
    setTypeXid: (state, action: PayloadAction<string>) => {
      state.typeXid = action.payload
    },
    setIsEventFilterFirstTime: (state, action: PayloadAction<boolean>) => {
      state.isEventFilterFirstTime = action.payload
    },
  },
})

export const {
  setIsEventFilter: jdihSetIsEventFilter,
  setIsEventFilterFirstTime: jdihSetIsEventFilterFirstTime,
  setSelected: jdihSetSelected,
  setSkip: jdihSetSkip,
  setLimit: jdihSetLimit,
  setKeyword: jdihSetKeyword,
  setYear: jdihSetYear,
  setTypeXid: jdihSetTypeXid,
} = jdih.actions

export default jdih.reducer
