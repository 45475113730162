import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const initialState: {
  isEventFilter: boolean
  selected: number
  skip: number
  limit: number
  title: string
  tags: string[]
} = {
  isEventFilter: false,
  selected: 0,
  skip: 0,
  limit: 12,
  title: '',
  tags: [],
}

export const pressRelease = createSlice({
  name: 'press-release-reducer',
  initialState,
  reducers: {
    setIsEventFilter: (state, action: PayloadAction<boolean>) => {
      state.isEventFilter = action.payload
    },
    setSelected: (state, action: PayloadAction<number>) => {
      state.selected = action.payload
    },
    setSkip: (state, action: PayloadAction<number>) => {
      state.skip = action.payload
    },
    setLimit: (state, action: PayloadAction<number>) => {
      state.limit = action.payload
    },
    setTitle: (state, action: PayloadAction<string>) => {
      state.title = action.payload
    },
    setTags: (state, action: PayloadAction<string[]>) => {
      state.tags = action.payload
    },
  },
})

export const {
  setIsEventFilter: pressReleaseSetIsEventFilter,
  setSelected: pressReleaseSetSelected,
  setSkip: pressReleaseSetSkip,
  setLimit: pressReleaseSetLimit,
  setTitle: pressReleaseSetTitle,
  setTags: pressReleaseSetTags,
} = pressRelease.actions

export default pressRelease.reducer
