import { createApi } from '@reduxjs/toolkit/query/react'
import { HYDRATE } from 'next-redux-wrapper'

import { BasicHeaders } from '@/types/common'
import {
  BaseRequest,
  JdihCategoryListRequest,
  JdihCategoryListResponse,
  JdihDetailResponse,
  JdihHeaderResponse,
  JdihListRequest,
  JdihListResponse,
} from '@/types/jdih'
import {} from '@/types/report'
import { apiBaseQuery } from '@/utils/api'

const api = createApi({
  reducerPath: 'jdih',
  baseQuery: apiBaseQuery,
  tagTypes: ['Jdih'],
  refetchOnMountOrArgChange: true,
  keepUnusedDataFor: 259200, // 3 days
  endpoints: (builder) => ({
    getJdihHeader: builder.query<JdihHeaderResponse, BasicHeaders>({
      query: ({ headers }) => ({
        headers,
        url: '/v2/jdih/header',
        providesTags: ['Jdih'],
      }),
    }),
    getJdihCategoriesList: builder.query<JdihCategoryListResponse, JdihCategoryListRequest>({
      query: ({ params, headers }) => ({
        headers,
        params,
        url: '/v1/jdih/categories',
        providesTags: ['Jdih'],
      }),
    }),
    getJdihList: builder.query<JdihListResponse, JdihListRequest>({
      query: ({ params, headers }) => ({
        headers,
        params,
        url: '/v1/jdihs',
        providesTags: ['Jdih'],
      }),
    }),
    getJdihDetail: builder.query<JdihDetailResponse, BaseRequest>({
      query: ({ headers, params: { slug } }) => ({
        headers,
        url: `/v1/jdih/${slug}/show`,
        providesTags: ['Jdih'],
      }),
    }),
  }),
  extractRehydrationInfo(action, { reducerPath }) {
    if (action.type === HYDRATE) {
      return action.payload[reducerPath]
    }
  },
})

// Export hooks for usage in functional components
export const {
  useLazyGetJdihListQuery,
  useLazyGetJdihCategoriesListQuery,
  useLazyGetJdihDetailQuery,
  util: jdihUtil,
} = api

// Export endpoints for use in SSR
export const { getJdihHeader, getJdihCategoriesList, getJdihList, getJdihDetail } = api.endpoints

export default api
