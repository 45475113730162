import { DateTime } from 'luxon'
import { stripHtml } from 'string-strip-html'
import UrlPattern from 'url-pattern'

export const formatEpoch = (seconds: number, format: string, lang = 'en') =>
  DateTime.fromSeconds(seconds).setLocale(lang).toFormat(format)

export function formatFileSize(bytes: number) {
  if (bytes == 0) return '0 Bytes'

  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
  const typeIndex = Math.floor(Math.log(bytes) / Math.log(1000))

  return parseFloat((bytes / Math.pow(1000, typeIndex)).toFixed(2)) + ' ' + sizes[typeIndex]
}

export const toFormatDate = (date: Date, format: string, lang = 'en') =>
  DateTime.fromJSDate(date).setLocale(lang).toFormat(format)
export const jsDateToEpoch = (date: Date) => DateTime.fromJSDate(date).toSeconds()
export const unixToJsDate = (seconds: number): Date => DateTime.fromSeconds(seconds).toJSDate()
export const replaceToNumber = (value: string) => value.replace(/[^\d]/g, '')
export const objectOmit = (obj: any, properties: any) => {
  const omittedObject: any = {}
  for (const key in obj) {
    if (!properties.includes(key)) {
      omittedObject[key] = obj[key]
    }
  }
  return omittedObject
}

export const objectPick = (obj: any, properties: any) => {
  const pickedObject: any = {}
  for (const key of properties) {
    if (key in obj) {
      pickedObject[key] = obj[key]
    }
  }
  return pickedObject
}

function objectToQueryParams(obj: any) {
  return Object.keys(obj)
    .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`)
    .join('&')
}

export function generateEndpoint(urlString: string, params: any = {}, query?: any) {
  const pattern = new UrlPattern(urlString)
  const endpoint = pattern.stringify(params)
  return query ? `${endpoint}?${objectToQueryParams(query)}` : endpoint
}

export const isObjEmpty = (obj: object | Record<string, never>) => Object.keys(obj).length === 0

export function getFileExtension(fileName: string) {
  return fileName.split('.').pop()
}

export const htmlToText = (text: string) => stripHtml(text).result

export const limitText = (text: string, maxLength = 160) => {
  return text.length > maxLength ? text.substring(0, maxLength) : text
}
