import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const initialState: { isShow: boolean } = {
  isShow: true,
}

export const googleMapReducer = createSlice({
  name: 'floating-menu-reducer',
  initialState,
  reducers: {
    setIsShow: (state, action: PayloadAction<boolean>) => {
      state.isShow = action.payload
    },
    clearData: (state) => {
      state.isShow = true
    },
  },
})

export const { setIsShow: floatingMenuSetIsShow, clearData: floatingMenuClearData } = googleMapReducer.actions

export default googleMapReducer.reducer
