import { createApi } from '@reduxjs/toolkit/query/react'
import { HYDRATE } from 'next-redux-wrapper'

import { TagListRequest, TagListResponse } from '@/types/tags'
import { apiBaseQuery } from '@/utils/api'

const api = createApi({
  reducerPath: 'tags',
  baseQuery: apiBaseQuery,
  tagTypes: ['Tags'],
  refetchOnMountOrArgChange: true,
  keepUnusedDataFor: 259200, // 3 days
  endpoints: (builder) => ({
    getTags: builder.query<TagListResponse, TagListRequest>({
      query: (sezRegionListRequest) => ({
        ...sezRegionListRequest,
        url: '/v1/tags',
        providesTags: ['Tags'],
      }),
    }),
  }),
  extractRehydrationInfo(action, { reducerPath }) {
    if (action.type === HYDRATE) {
      return action.payload[reducerPath]
    }
  },
})

// Export hooks for usage in functional components
export const { useLazyGetTagsQuery, util: tagsUtil } = api

// Export endpoints for use in SSR
export const { getTags } = api.endpoints

export default api
