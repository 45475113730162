import { createApi } from '@reduxjs/toolkit/query/react'
import { HYDRATE } from 'next-redux-wrapper'

import { BaseResponse, BasicHeaders } from '@/types/common'
import { ReportDownloadRequest, ReportHeaderResponse, ReportListRequest, ReportListResponse } from '@/types/report'
import { apiBaseQuery } from '@/utils/api'

const api = createApi({
  reducerPath: 'report',
  baseQuery: apiBaseQuery,
  tagTypes: ['Report'],
  refetchOnMountOrArgChange: true,
  keepUnusedDataFor: 259200, // 3 days
  endpoints: (builder) => ({
    getReportHeader: builder.query<ReportHeaderResponse, BasicHeaders>({
      query: ({ headers }) => ({
        headers,
        url: '/v2/implementation-report/header',
        providesTags: ['Report'],
      }),
    }),
    getReportList: builder.query<ReportListResponse, ReportListRequest>({
      query: ({ params, headers }) => ({
        params,
        headers,
        url: '/v2/implementation-reports',
        providesTags: ['Report'],
      }),
    }),
    postReportDownload: builder.mutation<BaseResponse<any>, ReportDownloadRequest>({
      query: ({ xid, body }) => ({
        method: 'POST',
        url: `/v1/implementation-report/${xid}/download-form/store`,
        body,
      }),
      invalidatesTags: ['Report'],
    }),
  }),
  extractRehydrationInfo(action, { reducerPath }) {
    if (action.type === HYDRATE) {
      return action.payload[reducerPath]
    }
  },
})

// Export hooks for usage in functional components
export const {
  useLazyGetReportHeaderQuery,
  useLazyGetReportListQuery,
  usePostReportDownloadMutation,
  util: reportUtil,
} = api

// Export endpoints for use in SSR
export const { getReportHeader, getReportList } = api.endpoints

export default api
