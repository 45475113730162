import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const initialState: { isRedirectHowToInvest: boolean } = {
  isRedirectHowToInvest: false,
}

export const distribution = createSlice({
  name: 'distribution-reducer',
  initialState,
  reducers: {
    setIsRedirectHowToInvest: (state, action: PayloadAction<boolean>) => {
      state.isRedirectHowToInvest = action.payload
    },
  },
})

export const { setIsRedirectHowToInvest: distributionSetIsRedirectHowToInvest } = distribution.actions

export default distribution.reducer
