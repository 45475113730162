import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const initialState: { isRedirectFiscal: boolean; isRedirectNonFiscal: boolean; isRedirectTaxHoliday: boolean } = {
  isRedirectFiscal: false,
  isRedirectNonFiscal: false,
  isRedirectTaxHoliday: false,
}

export const incentive = createSlice({
  name: 'incentive-reducer',
  initialState,
  reducers: {
    setIsRedirectTaxHoliday: (state, action: PayloadAction<boolean>) => {
      state.isRedirectTaxHoliday = action.payload
    },
    setIsRedirectNonFiscal: (state, action: PayloadAction<boolean>) => {
      state.isRedirectNonFiscal = action.payload
    },
    setIsRedirectFiscal: (state, action: PayloadAction<boolean>) => {
      state.isRedirectFiscal = action.payload
    },
  },
})

export const {
  setIsRedirectTaxHoliday: incentiveSetIsRedirectTaxHoliday,
  setIsRedirectNonFiscal: incentiveSetIsRedirectNonFiscal,
  setIsRedirectFiscal: incentiveSetIsRedirectFiscal,
} = incentive.actions

export default incentive.reducer
