import { createApi } from '@reduxjs/toolkit/query/react'
import { HYDRATE } from 'next-redux-wrapper'

import { FloatingResponse, FooterResponse } from '@/types/apps'
import { BasicHeaders } from '@/types/common'
import { apiBaseQuery } from '@/utils/api'

const api = createApi({
  reducerPath: 'apps',
  baseQuery: apiBaseQuery,
  tagTypes: ['Apps'],
  refetchOnMountOrArgChange: true,
  keepUnusedDataFor: 259200, // 3 days
  endpoints: (builder) => ({
    getFloatingMenu: builder.query<FloatingResponse, BasicHeaders>({
      query: ({ headers }) => ({
        headers,
        url: '/v2/floating-buttons',
        providesTags: ['Apps'],
      }),
    }),
    getFooter: builder.query<FooterResponse, BasicHeaders>({
      query: ({ headers }) => ({
        headers,
        url: '/v2/footer',
        providesTags: ['Apps'],
      }),
    }),
  }),
  extractRehydrationInfo(action, { reducerPath }) {
    if (action.type === HYDRATE) {
      return action.payload[reducerPath]
    }
  },
})

// Export hooks for usage in functional components
export const { useLazyGetFloatingMenuQuery, useLazyGetFooterQuery, util: aboutUtil } = api

// Export endpoints for use in SSR
export const { getFloatingMenu, getFooter } = api.endpoints

export default api
