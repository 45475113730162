import { createApi } from '@reduxjs/toolkit/query/react'
import { HYDRATE } from 'next-redux-wrapper'

import { BaseResponse, ListBasicRequest } from '@/types/common'
import { ContentListResponse } from '@/types/ppid-survey'
import { QuestionListResponse, SurveyRequestForm } from '@/types/ppid-survey'
import { apiBaseQuery } from '@/utils/api'

const api = createApi({
  reducerPath: 'ppid-survey',
  baseQuery: apiBaseQuery,
  tagTypes: ['PPID Survey'],
  refetchOnMountOrArgChange: true,
  keepUnusedDataFor: 259200, // 3 days
  endpoints: (builder) => ({
    getPpidSurveyUserJobs: builder.query<ContentListResponse, ListBasicRequest>({
      query: (request) => ({
        ...request,
        url: '/v1/user-jobs',
        providesTags: ['PPID Survey'],
      }),
    }),
    getPpidSurveyEducations: builder.query<ContentListResponse, ListBasicRequest>({
      query: (request) => ({
        ...request,
        url: '/v1/educations',
        providesTags: ['PPID Survey'],
      }),
    }),
    getPpidSurveyQuestion: builder.query<QuestionListResponse, ListBasicRequest>({
      query: (request) => ({
        ...request,
        url: '/v1/ppid/survey-questions',
        providesTags: ['PPID Survey'],
      }),
    }),
    ppidSentSurvey: builder.mutation<BaseResponse<any>, SurveyRequestForm>({
      query: ({ body }) => ({
        method: 'POST',
        url: '/v1/ppid/satisfaction-form/store',
        body,
      }),
      invalidatesTags: ['PPID Survey'],
    }),
  }),
  extractRehydrationInfo(action, { reducerPath }) {
    if (action.type === HYDRATE) {
      return action.payload[reducerPath]
    }
  },
})

// Export hooks for usage in functional components
export const { usePpidSentSurveyMutation, util: tagsUtil } = api

// Export endpoints for use in SSR
export const { getPpidSurveyUserJobs, getPpidSurveyEducations, getPpidSurveyQuestion } = api.endpoints

export default api
