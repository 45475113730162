import { createApi } from '@reduxjs/toolkit/query/react'
import { HYDRATE } from 'next-redux-wrapper'

import { ListBasicRequest } from '@/types/common'
import { JobListResponse } from '@/types/jobs'
import { apiBaseQuery } from '@/utils/api'

const api = createApi({
  reducerPath: 'jobs',
  baseQuery: apiBaseQuery,
  tagTypes: ['Report'],
  refetchOnMountOrArgChange: true,
  keepUnusedDataFor: 259200, // 3 days
  endpoints: (builder) => ({
    getJobList: builder.query<JobListResponse, ListBasicRequest>({
      query: (listRequest) => ({
        ...listRequest,
        url: '/v1/user-jobs',
        providesTags: ['Report'],
      }),
    }),
  }),
  extractRehydrationInfo(action, { reducerPath }) {
    if (action.type === HYDRATE) {
      return action.payload[reducerPath]
    }
  },
})

// Export hooks for usage in functional components
export const { useLazyGetJobListQuery, util: jobsUtil } = api

// Export endpoints for use in SSR
export const { getJobList } = api.endpoints

export default api
