import app from '@/reducers/app'
import distribution from '@/reducers/distribution'
import floatingMenu from '@/reducers/floating-menu'
import googleMap from '@/reducers/google-map'
import incentiveReducer from '@/reducers/incentive'
import jdih from '@/reducers/jdih'
import pressRelease from '@/reducers/press-release'

const rootReducers = {
  Jdih: jdih,
  googleMap,
  floatingMenu,
  incentiveReducer,
  distribution,
  app,
  pressRelease,
}

export default rootReducers
