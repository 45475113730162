import { createApi } from '@reduxjs/toolkit/query/react'
import { HYDRATE } from 'next-redux-wrapper'

import { BasicHeaders, ListBasicRequest } from '@/types/common'
import {
  WhySezBannerResponse,
  WhySezInfographicEconomicGrowthResponse,
  WhySezInfographicPopulationResponse,
  WhySezInfographicResponse,
  WhySezInfrastructureConnectivityListResponse,
  WhySezInfrastructureConnectivityResponse,
  WhySezRegulationPolicyResponse,
} from '@/types/why-sez'
import { apiBaseQuery } from '@/utils/api'

const api = createApi({
  reducerPath: 'why-invest',
  baseQuery: apiBaseQuery,
  tagTypes: ['Why Invest'],
  refetchOnMountOrArgChange: true,
  keepUnusedDataFor: 259200, // 3 days
  endpoints: (builder) => ({
    getWhySezBanner: builder.query<WhySezBannerResponse, BasicHeaders>({
      query: ({ headers }) => ({
        headers,
        url: '/v2/why-sez/banner',
        providesTags: ['Why Invest'],
      }),
    }),
    getWhySezRegulationPolicy: builder.query<WhySezRegulationPolicyResponse, BasicHeaders>({
      query: ({ headers }) => ({
        headers,
        url: '/v2/why-sez/regulation-policies',
        providesTags: ['Why Invest'],
      }),
    }),
    getWhySezInfrastructureConnectivity: builder.query<WhySezInfrastructureConnectivityResponse, BasicHeaders>({
      query: ({ headers }) => ({
        headers,
        url: '/v2/why-sez/infrastructure-connectivity/description',
        providesTags: ['Why Invest'],
      }),
    }),
    getWhySezInfrastructureConnectivityList: builder.query<
      WhySezInfrastructureConnectivityListResponse,
      ListBasicRequest
    >({
      query: ({ headers }) => ({
        headers,
        url: '/v2/why-sez/infrastructure-connectivity/galleries',
        providesTags: ['Why Invest'],
      }),
    }),
    getWhySezInfographicStrategicLocation: builder.query<WhySezInfographicResponse, BasicHeaders>({
      query: ({ headers }) => ({
        headers,
        url: '/v2/why-sez/infographic/strategic-location',
        providesTags: ['Why Invest'],
      }),
    }),
    getWhySezInfographicNaturalResources: builder.query<WhySezInfographicResponse, BasicHeaders>({
      query: ({ headers }) => ({
        headers,
        url: '/v2/why-sez/infographic/natural-resources',
        providesTags: ['Why Invest'],
      }),
    }),
    getWhySezInfographicPopulation: builder.query<WhySezInfographicPopulationResponse, BasicHeaders>({
      query: ({ headers }) => ({
        headers,
        url: '/v2/why-sez/infographic/population',
        providesTags: ['Why Invest'],
      }),
    }),
    getWhySezInfographicEconomicGrowth: builder.query<WhySezInfographicEconomicGrowthResponse, BasicHeaders>({
      query: ({ headers }) => ({
        headers,
        url: '/v2/why-sez/infographic/economic-growth',
        providesTags: ['Why Invest'],
      }),
    }),
  }),
  extractRehydrationInfo(action, { reducerPath }) {
    if (action.type === HYDRATE) {
      return action.payload[reducerPath]
    }
  },
})

// Export hooks for usage in functional components
export const { util: whyInvestUtil } = api

// Export endpoints for use in SSR
export const {
  getWhySezBanner,
  getWhySezRegulationPolicy,
  getWhySezInfrastructureConnectivity,
  getWhySezInfrastructureConnectivityList,
  getWhySezInfographicStrategicLocation,
  getWhySezInfographicNaturalResources,
  getWhySezInfographicPopulation,
  getWhySezInfographicEconomicGrowth,
} = api.endpoints

export default api
