import { createApi } from '@reduxjs/toolkit/query/react'
import { HYDRATE } from 'next-redux-wrapper'

import {
  BuildNewSezDeclarationSezResponse,
  BuildNewSezFaqListResponse,
  BuildNewSezHeaderResponse,
  BuildNewSezHowToBuildResponse,
  BuildNewSezLocationCriteriaResponse,
  BuildNewSezProposedDocumentResponse,
  BuildNewSezProposedProcedureResponse,
  BuildNewSezProposeNewResponse,
} from '@/types/build-new-sez'
import { BasicHeaders } from '@/types/common'
import { apiBaseQuery } from '@/utils/api'

const api = createApi({
  reducerPath: 'build-new-sez',
  baseQuery: apiBaseQuery,
  tagTypes: ['Build New'],
  refetchOnMountOrArgChange: true,
  keepUnusedDataFor: 259200, // 3 days
  endpoints: (builder) => ({
    getBuildNewSezHeader: builder.query<BuildNewSezHeaderResponse, BasicHeaders>({
      query: ({ headers }) => ({
        headers,
        url: '/v2/build-new-sez/header',
        providesTags: ['Build New'],
      }),
    }),
    getBuildNewSezProposeNew: builder.query<BuildNewSezProposeNewResponse, BasicHeaders>({
      query: ({ headers }) => ({
        headers,
        url: '/v2/build-new-sez/propose-new',
        providesTags: ['Build New'],
      }),
    }),
    getBuildNewSezHowToBuild: builder.query<BuildNewSezHowToBuildResponse, BasicHeaders>({
      query: ({ headers }) => ({
        headers,
        url: '/v1/build-new-sez/how-to-build',
        providesTags: ['Build New'],
      }),
    }),
    getBuildNewSezLocationCriteria: builder.query<BuildNewSezLocationCriteriaResponse, BasicHeaders>({
      query: ({ headers }) => ({
        headers,
        url: '/v2/build-new-sez/location-criteria',
        providesTags: ['Build New'],
      }),
    }),
    getBuildNewSezProposedProcedure: builder.query<BuildNewSezProposedProcedureResponse, BasicHeaders>({
      query: ({ headers }) => ({
        headers,
        url: '/v2/build-new-sez/proposed-procedure',
        providesTags: ['Build New'],
      }),
    }),
    getBuildNewSezProposedDocument: builder.query<BuildNewSezProposedDocumentResponse, BasicHeaders>({
      query: ({ headers }) => ({
        headers,
        url: '/v2/build-new-sez/proposed-document',
        providesTags: ['Build New'],
      }),
    }),
    getBuildNewSezDeclarationSez: builder.query<BuildNewSezDeclarationSezResponse, BasicHeaders>({
      query: ({ headers }) => ({
        headers,
        url: '/v2/build-new-sez/declaration',
        providesTags: ['Build New'],
      }),
    }),
    getBuildNewSezFaqList: builder.query<BuildNewSezFaqListResponse, BasicHeaders>({
      query: ({ headers }) => ({
        headers,
        url: '/v1/build-new-sez/faq',
        providesTags: ['Build New'],
      }),
    }),
  }),
  extractRehydrationInfo(action, { reducerPath }) {
    if (action.type === HYDRATE) {
      return action.payload[reducerPath]
    }
  },
})

// Export hooks for usage in functional components
export const { util: aboutUtil } = api

// Export endpoints for use in SSR
export const {
  getBuildNewSezHeader,
  getBuildNewSezProposeNew,
  getBuildNewSezFaqList,
  getBuildNewSezLocationCriteria,
  getBuildNewSezProposedProcedure,
  getBuildNewSezProposedDocument,
  getBuildNewSezDeclarationSez,
  getBuildNewSezHowToBuild,
} = api.endpoints

export default api
