import { createApi } from '@reduxjs/toolkit/query/react'
import { HYDRATE } from 'next-redux-wrapper'

import { BasicHeaders, ListBasicRequest } from '@/types/common'
import {
  HomeAboutResponse,
  HomeBannerResponse,
  HomeBusinessOpportunityListRequest,
  HomeBusinessOpportunityListResponse,
  HomeGlimpseResponse,
  HomeIncentiveResponse,
  HomeInvestorsResponse,
  HomeMoreIncentiveResponse,
} from '@/types/home'
import { apiBaseQuery } from '@/utils/api'

const api = createApi({
  reducerPath: 'home',
  baseQuery: apiBaseQuery,
  tagTypes: ['Home'],
  refetchOnMountOrArgChange: true,
  keepUnusedDataFor: 259200, // 3 days
  endpoints: (builder) => ({
    getHomeBanner: builder.query<HomeBannerResponse, ListBasicRequest>({
      query: (sezRegionListRequest) => ({
        ...sezRegionListRequest,
        url: '/v2/homepage/banners',
        providesTags: ['Home'],
      }),
    }),
    getHomeAbout: builder.query<HomeAboutResponse, BasicHeaders>({
      query: (sezRegionListRequest) => ({
        ...sezRegionListRequest,
        url: '/v2/homepage/about',
        providesTags: ['Home'],
      }),
    }),
    getHomeIncentive: builder.query<HomeIncentiveResponse, BasicHeaders>({
      query: (sezRegionListRequest) => ({
        ...sezRegionListRequest,
        url: '/v2/homepage/incentive',
        providesTags: ['Home'],
      }),
    }),
    getHomeMoreIncentive: builder.query<HomeMoreIncentiveResponse, BasicHeaders>({
      query: (sezRegionListRequest) => ({
        ...sezRegionListRequest,
        url: '/v2/homepage/more-incentive',
        providesTags: ['Home'],
      }),
    }),
    getHomeInvestors: builder.query<HomeInvestorsResponse, BasicHeaders>({
      query: (sezRegionListRequest) => ({
        ...sezRegionListRequest,
        url: '/v2/homepage/investors',
        providesTags: ['Home'],
      }),
    }),
    getHomeGlimpse: builder.query<HomeGlimpseResponse, BasicHeaders>({
      query: (sezRegionListRequest) => ({
        ...sezRegionListRequest,
        url: '/v2/homepage/glimpse',
        providesTags: ['Home'],
      }),
    }),
    getHomeBusinessOpportunity: builder.query<HomeBusinessOpportunityListResponse, HomeBusinessOpportunityListRequest>({
      query: (sezRegionListRequest) => ({
        ...sezRegionListRequest,
        url: '/v2/homepage/business-opportunity',
        providesTags: ['Home'],
      }),
    }),
  }),
  extractRehydrationInfo(action, { reducerPath }) {
    if (action.type === HYDRATE) {
      return action.payload[reducerPath]
    }
  },
})

// Export hooks for usage in functional components
export const {
  useLazyGetHomeBannerQuery,
  useLazyGetHomeIncentiveQuery,
  useLazyGetHomeAboutQuery,
  useLazyGetHomeMoreIncentiveQuery,
  useLazyGetHomeInvestorsQuery,
  useLazyGetHomeGlimpseQuery,
  util: homeUtil,
} = api

// Export endpoints for use in SSR
export const {
  getHomeAbout,
  getHomeBanner,
  getHomeIncentive,
  getHomeGlimpse,
  getHomeMoreIncentive,
  getHomeInvestors,
  getHomeBusinessOpportunity,
} = api.endpoints

export default api
